import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./plugins/fontawesomeicon.js";
library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// 뷰 캐러셀
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
}).$mount("#app");
