var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"position-fixed d-flex flex-column align-items-center text-center justify-content-center",style:({
    top: '50%',
    right: '2%',
    transform: 'translateY(-50%)',
    zIndex: '1000',
  })},[_c('div',{staticClass:"mt-auto pt-2 pt-md-0 mb-3 mb-md-5"},[_c('b-btn',{staticClass:"mt-5 mt-md-0 mb-md-5 p-0",attrs:{"variant":"transparant"},on:{"click":_vm.scrollTo}},[(_vm.options[_vm.active].type === 'white')?_c('i',{staticClass:"icon icon-sm-24 icon-45 icon-scroll-top mx-0"}):_c('i',{staticClass:"icon icon-sm-24 icon-45 icon-scroll-top-dark mx-0"})])],1),(_vm.sections)?_c('ul',{staticClass:"mb-auto p-0 pt-3 list-unstyled",class:_vm.options[_vm.active].type === 'white' ? 'text-white' : 'text-gray-dark'},[_c('li',{staticClass:"m-0 mb-3 p-0"},[_vm._v(" 01 ")]),_vm._l((_vm.sections),function(item,i){return _c('li',{key:i},[_c('b-btn',{staticClass:"button-section",class:[
          item.idx <= _vm.active ? 'active' : '',
          _vm.options[_vm.active].type === 'white' ? 'bg-white' : 'bg-gray-dark' ],attrs:{"variant":"link","href":("#" + (item.idx))},on:{"click":function($event){_vm.active = item.idx}}})],1)}),_c('li',{staticClass:"m-0 mt-3 p-0"},[_vm._v("0"+_vm._s(_vm.sections.length))])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }