<template>
  <footer
    :style="{
      backgroundColor: '#0E0D0E',
    }"
    class="w-100 py-4  text-center"
  >
    <ul class="list-unstyled text-white text-15 fw-300">
      <li class="my-2">
        © 2020 ROST. All rights reserved
      </li>
      <li class="my-2 d-lg-flex align-items-center justify-content-center">
        <ul class="list-unstyled d-block d-md-flex">
          <li class="mx-3">
            <b-btn
              variant="link text-white mx-1 mx-md-3 p-0"
              @click="$router.push('/policy')"
              >PRIVACY POLICY</b-btn
            >
          </li>
          <!-- <li class="my-3 my-md-0 d-none d-md-block">
            <span class="text-white">/</span>
          </li>
          <li class="mx-3">
            <b-btn
              variant="link text-white mx-1 mx-md-3 p-0"
              @click="$router.push('/policy')"
              >TERMS AND CONDITIONS</b-btn
            >
          </li> -->
          <li class="my-3 my-md-0 d-none d-md-block">
            <span class="text-white">/</span>
          </li>
          <li class="mx-3">
            <b-btn
              variant="link text-white mx-1 mx-md-3 p-0"
              @click="$router.push('/introduce')"
              >FAQ</b-btn
            >
          </li>
        </ul>
      </li>
      <li class="mt-4 d-flex align-items-center justify-content-center">
        <b-btn
          variant="white"
          class="rounded-circle mx-2"
          :style="{ opacity: '0.8', maxWidth: '42px', maxHeight: '42px' }"
        >
          <font-awesome-icon
            :icon="['fab', 'instagram']"
            class="mt-1 text-darkest text-20"
          />
        </b-btn>
        <b-btn
          variant="white"
          class="rounded-circle mx-2"
          :style="{ opacity: '0.8', maxWidth: '42px', maxHeight: '42px' }"
        >
          <font-awesome-icon
            :icon="['fab', 'youtube']"
            class="mt-1 ml-n1 text-darkest text-20"
          />
        </b-btn>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
