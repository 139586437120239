import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Apply from "../views/Apply.vue";
import Introduce from "../views/Introduce.vue";
import Policy from "../views/Policy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      handleScroll: true,
    },
  },
  {
    path: "/apply",
    name: "Apply",
    component: Apply,
    meta: {
      handleScroll: true,
    },
  },
  {
    path: "/introduce",
    name: "Introduce",
    component: Introduce,
    meta: {
      handleScroll: false,
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    meta: {
      handleScroll: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
