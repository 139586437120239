<template>
  <main
    class="py-5 position-relative"
    :style="{
      backgroundImage: `url(${require('@/assets/bg-introduce.png')})`,
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <div class="pt-5"></div>
    <b-container class="text-center my-5 py-5 p-3 px-md-0">
      <img
        :src="require('@/assets/logo-lg.svg')"
        alt=""
        class="logo mt-lg-5 d-none d-md-block mx-auto"
        :style="{ maxWidth: '80%' }"
      />
      <img
        :src="require('@/assets/logo-white.svg')"
        alt=""
        class="logo mt-lg-5 d-block d-md-none mx-auto"
        :style="{ width: '80%' }"
      />
      <header class="my-5 pt-5">
        <h1
          class="text-heading mx-auto mb-0 w-lg-25 pt-2 text-32 text-lg-40 highlight highlight-light text-nowrap"
        >
          <!-- <img
            :src="require('@/assets/heading/h1-introduce.svg')"
            alt=""
            class="h-100 mx-auto"
          /> -->
          ROST 인사말
        </h1>
      </header>
      <section class="p-3 p-md-5">
        <p class="lh-240 px-md-5 text-14 text-lg-16">
          회원가입에 진심으로 감사의 인사를 드립니다. <br />
          저희는 스포츠 데이터 플랫폼 회사로서 여러분과 함께 더 나은 스쿼시
          환경을 만들어나가고자 합니다. <br />최근 대한민국 스쿼시는 그 어느
          때보다도 역동적이고 밝은 미래를 기대하게 하는 뜻깊은 시간이었습니다.
          <br />주로 30, 40대로 한정되었던 스쿼시가 비로소 전 연령층을 아우를 수
          있는 국민스포츠로 자리매김하였고, 지금 이 순간에도 전국적으로 계속해서
          훌륭한 코트 인프라가 확장되어가고 있으며, 최근 미디어 콘텐츠 제작
          활동도 활발해지는 등 폭넓은 성장을 꾸준히 이어가고 있습니다.
          <br />하지만 많은 분이 공감하듯, 작금의 스쿼시 대회는 반복되는
          새내기부의 출전자격 문제, 지역마다 다른 기준으로 인한 참가구분 문제,
          데이터의 부재로 인한 시드배정 문제 등, 몇 가지 해결과제를 안고
          있습니다. <br />저희는 이러한 문제들의 해결을 위해 여러분께 새롭고
          신선한 제안을 하려고 합니다. <br />모든 대회의 데이터가 기록되고,
          공신력 있는 랭킹이 산정되며, 누구든지 쉽게 열람할 수 있는 시스템이
          만들어진다면 우리의 스쿼시 환경은 어떻게 변화하게 될까요? 한 번쯤
          상상해봤던 일이지만 매번 상상으로만 그쳤던 그 일들이 바로 지금부터
          시작됩니다. <br />앞으로 대한민국에서 개최되는 모든 스쿼시 대회는
          투명하게 ‘참가선수 정보’가 수집, 공개되고, 정교하게 측정된
          ‘랭킹포인트’ 가 부여될 것입니다. <br />이러한 데이터들은 대회
          운영진들에게 더욱 공정하고 효율성 있는 대회 운영을 유도하여
          참가자들에게 더 나은 대회 환경으로 다시 돌아갈 것입니다. <br />또한,
          수작업으로 이루어지던 대회 운영 전반의 과정들은 모두 스마트 디바이스를
          통한 자동화 시스템으로 대체될 것입니다. <br />이 시스템은 이제부터
          ‘Ranking of Sports Tournament’ 즉 ‘ROST’라 불리게 될 것입니다.
          <br />긴 세월 때로는 참여자로서, 때로는 주최자로서 언젠가 이러한
          선진화된 대회 운영 시스템이 구축되기를 바라고 또 바라왔습니다.
          <br />하지만 이제 더는 그러한 수동적인 기다림에 머무르지 않기로
          했습니다. <br />ROST의 이러한 첫걸음이 작은 불씨가 되어 앞으로
          대한민국 스쿼시가 더는 비인기 종목에 머무르지 않고 우리나라를 대표하는
          스포츠로 발전하길 기원합니다. <br />다시 한번 회원 가입에
          감사드립니다. <br />랭킹을 부여받은 아마추어 선수로서, 여러분이 오직
          승부에만 집중할 수 있는 환경을 만들어 드리겠습니다.<br />
        </p>
      </section>
    </b-container>

    <img
      :src="require('@/assets/balls/ball-introduce-1.png')"
      class="position-absolute pointer-events-none d-none d-md-block"
      :style="{
        top: '15%',
        right: '10%',
        zIndex: '1',
      }"
      alt=""
    />
    <img
      :src="require('@/assets/balls/ball-introduce-1.png')"
      class="position-absolute pointer-events-none d-block d-md-none"
      :style="{
        top: '4%',
        right: '3%',
        zIndex: '1',
        width: '30%',
      }"
      alt=""
    />

    <img
      :src="require('@/assets/balls/ball-introduce-2.png')"
      class="position-absolute pointer-events-none d-none d-md-block"
      :style="{
        top: '30%',
        left: '10%',
        zIndex: '1',
      }"
      alt=""
    />
    <img
      :src="require('@/assets/balls/ball-introduce-2.png')"
      class="position-absolute pointer-events-none d-md-none d-block"
      :style="{
        bottom: '3%',
        left: '-5%',
        zIndex: '1',
      }"
      alt=""
    />

    <img
      :src="require('@/assets/balls/ball-introduce-3.png')"
      class="position-absolute pointer-events-none d-none d-md-block"
      :style="{
        top: '63%',
        left: '5%',
        zIndex: '1',
      }"
      alt=""
    />

    <img
      :src="require('@/assets/balls/ball-introduce-4.png')"
      class="position-absolute pointer-events-none d-none d-md-block"
      :style="{
        bottom: '5%',
        right: '10%',
        zIndex: '1',
      }"
      alt=""
    />
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
