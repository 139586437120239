var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"pt-2 pt-md-4 pb-md-3 fixed-top",style:({
    transition: '0.2s',
    zIndex: '1000',
    paddingLeft: '10%',
    paddingRight: '10%',
  }),attrs:{"toggleable":"md","variant":_vm.navActive ? 'white' : 'transparant',"tag":"nav"}},[_c('b-navbar-brand',{on:{"click":_vm.gotoHome}},[_c('img',{staticClass:"logo",style:({ maxWidth: '144px', cursor: 'pointer' }),attrs:{"src":_vm.navActive
          ? require('@/assets/logo.svg')
          : require('@/assets/logo-white.svg'),"alt":""}})]),(_vm.$route.path === '/' || _vm.$route.path === '/apply')?_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}):_vm._e(),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(_vm.$route.path === '/')?_c('b-navbar-nav',{staticClass:"ml-auto mr-5"},_vm._l((_vm.links),function(link,i){return _c('a',{key:i,staticClass:"mr-4 my-2 my-md-0",class:_vm.navActive ? 'text-gray-dark' : 'text-white',attrs:{"href":"#" + (i + 1)}},[_vm._v(" "+_vm._s(link.name))])}),0):_vm._e(),(_vm.$route.path === '/')?_c('b-navbar-nav',{staticClass:"mr-0 mt-n1"},[_c('b-btn',{staticClass:"shadow-sm rounded my-2 my-md-0 text-nowrap",style:({ width: '7rem' }),attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push('/apply')}}},[_vm._v(" 제휴 및 문의 ")])],1):_vm._e(),(_vm.$route.path === '/apply')?_c('b-navbar-nav',{staticClass:"ml-auto mt-n1"},[_c('b-btn',{staticClass:"shadow-sm rounded my-2 my-md-0 px-3",style:({ width: '7rem' }),attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push('/introduce')}}},[_vm._v(" ABOUT US ")])],1):_vm._e(),(_vm.$route.path === '/introduce')?_c('b-navbar-nav',{staticClass:"ml-auto mt-n1"},[_c('b-btn',{staticClass:"shadow-sm rounded my-2 my-md-0 px-3",style:({ width: '7rem' }),attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" HOME ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }