<template>
  <aside
    class="position-fixed d-flex flex-column align-items-center text-center justify-content-center"
    :style="{
      top: '50%',
      right: '2%',
      transform: 'translateY(-50%)',
      zIndex: '1000',
    }"
  >
    <div class="mt-auto pt-2 pt-md-0 mb-3 mb-md-5">
      <b-btn
        variant="transparant"
        @click="scrollTo"
        class="mt-5 mt-md-0 mb-md-5 p-0"
      >
        <i
          class="icon icon-sm-24 icon-45 icon-scroll-top mx-0"
          v-if="options[active].type === 'white'"
        ></i>
        <i class="icon icon-sm-24 icon-45 icon-scroll-top-dark mx-0" v-else></i>
      </b-btn>
    </div>
    <ul
      class="mb-auto p-0 pt-3 list-unstyled"
      :class="
        options[active].type === 'white' ? 'text-white' : 'text-gray-dark'
      "
      v-if="sections"
    >
      <li class="m-0 mb-3 p-0">
        01
      </li>
      <li class="" v-for="(item, i) in sections" :key="i">
        <b-btn
          variant="link"
          class="button-section"
          :class="[
            item.idx <= active ? 'active' : '',
            options[active].type === 'white' ? 'bg-white' : 'bg-gray-dark',
          ]"
          @click="active = item.idx"
          :href="`#${item.idx}`"
        ></b-btn>
      </li>
      <li class="m-0 mt-3 p-0">0{{ sections.length }}</li>
    </ul>
  </aside>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    scrollTo() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-section {
  width: 4px;
  height: 2rem;
  margin: 0;
  padding: 0;
  border: 0;
  opacity: 0.2;
  transition: 0.2s;
  &.active {
    opacity: 1;
  }
}
</style>
