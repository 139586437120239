export default [
  {
    title: "개인정보의 처리 목적",
    list: [
      `“회사”는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
    `,
      `- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별∙인증, 회원자격 유지∙관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급∙배송 등
    `,
    ],
  },
  {
    title: "개인정보의 처리 및 보유 기간",
    list: [
      `① “회사”는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유∙이용기간 또는 법령에 따른 개인정보 보유∙이용기간 내에서 개인정보를 처리∙보유합니다.
    `,
      `② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
    `,
      `- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
    `,
      `- 전자상거래에서의 계약∙청약철회, 대금결제, 재화 등 공급기록 : 5년
    `,
    ],
  },
  {
    title: "개인정보의 제3자 제공",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보처리의 위탁",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "정보주체와 법정대리인의 권리·의무 및 그 행사방법",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "처리하는 개인정보",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보의 파기",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보의 안전성 확보 조치에 관한 사항",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보 자동 수집 장치의 설치∙운영 및 거부",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보 보호책임자",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
  {
    title: "개인정보 처리방침의 변경",
    list: [
      `① “회사”는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    `,
      `② “회사”는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    `,
      `- 개인정보를 제공받는 자 : (주)LG유플러스
    `,
      `- 제공받는 자의 개인정보 이용목적 : 본인확인, 사고 및 리스크 관리, 통계 활용, 결제결과통보, 신용카드, 계좌이체, 가상계좌, 휴대폰결제, 유선전화결제 등 결제서비스 제공, 결제 결과 조회 및 통보 등
    `,
      `- 제공하는 개인정보 항목 : 이메일, 휴대전화번호, 자택전화번호, 비밀번호, 생년월일, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 결제정보 등 
    `,
      `- 제공받는 자의 보유∙이용기간: 지체없이 파기. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존.
    `,
      `가. ㈜LG유플러스 내부방침에 의한 정보 보유 사유: 본 전자결제서비스 계약상의 권리, 의무의 이행 등
    `,
      `나. 관련 법령에 의한 정보 보유 사유: 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 ㈜LG유플러스는 관계 법령에서 정한 일정한 기간 동안 개인정보를 보관 등
    `,
    ],
  },
];
