<template>
  <main class="home">
    <article id="0">
      <section class="vh-100 px-0 position-relative">
        <div
          class="position-absolute shadow-spread"
          :style="{
            width: '78%',
            maxWidth: '1696px',
            height: '77%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#161616',
            backgroundImage: `url(${require('@/assets/bg-intro.png')})`,
            backgroundPosition: 'right bottom',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }"
        >
          <b-container class="h-100 p-5">
            <b-row class="h-100" align-v="center">
              <b-col cols md="8" lg="6" class="text-white h-100">
                <section class="w-100 h-100 mt-1 mt-md-0 pt-md-5">
                  <div class="d-none d-lg-flex align-items-end my-5">
                    <div
                      class="vl"
                      :style="{
                        width: '3px',
                        height: '6rem',
                        background: 'white',
                      }"
                    ></div>
                    <div
                      class="hl ml-4"
                      :style="{
                        width: '6rem',
                        height: '3px',
                        background: 'white',
                      }"
                    ></div>
                  </div>

                  <h1
                    class="text-heading text-white text-30 text-lg-64 highlight highlight-primary text-nowrap mb-4"
                  >
                    스쿼시를 위한<br />
                    모든 데이터.
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-1.svg')"
                      alt=""
                      class="h-100 d-none d-md-block"
                    />
                    <img
                      :src="require('@/assets/heading/h1-home-1.svg')"
                      alt=""
                      class="w-100 d-block d-md-none"
                    /> -->
                  </h1>
                  <h2 class="text-white text-20 text-lg-32">
                    스포츠 데이터 플랫폼, <br class="d-block d-md-none" />
                    로스트
                  </h2>

                  <div
                    class="mt-4 mt-md-5 d-md-flex align-items-start position-relative"
                  >
                    <b-btn variant="transparant" class="p-0 mr-3">
                      <img
                        class=""
                        :src="require('@/assets/appstore.png')"
                        alt=""
                        :style="{ maxWidth: '140px' }"
                      />
                    </b-btn>
                    <b-btn variant="transparant" class="p-0">
                      <img
                        class="h"
                        :src="require('@/assets/googleplay.png')"
                        alt=""
                        :style="{ maxWidth: '140px' }"
                      />
                    </b-btn>
                    <img
                      :src="require('@/assets/balls/ball-intro-1.png')"
                      class="position-absolute pointer-events-none d-none d-md-block"
                      :style="{
                        bottom: '-10rem',
                        left: '-3rem',
                        zIndex: '-1',
                      }"
                      alt=""
                    />
                    <img
                      :src="require('@/assets/balls/ball-intro-1.png')"
                      class="position-absolute pointer-events-none d-md-none d-block"
                      :style="{
                        bottom: '-10rem',
                        left: '-50%',
                        zIndex: '-1',
                      }"
                      alt=""
                    />
                    <img
                      :src="require('@/assets/balls/ball-intro-2.png')"
                      class="position-absolute pointer-events-none d-none d-md-block"
                      :style="{ bottom: '1rem', right: '15%', zIndex: '-1' }"
                      alt=""
                    />
                    <img
                      :src="require('@/assets/balls/ball-intro-2.png')"
                      class="position-absolute pointer-events-none d-md-none d-block"
                      :style="{ top: '-40%', right: '-30%', zIndex: '-1' }"
                      alt=""
                    />
                  </div>
                </section>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="w-100 bg-primary" :style="{ height: '40%' }"></div>
        <div
          class="h-100"
          :style="{ height: '60%', backgroundColor: '#0E0D0E' }"
        ></div>
        <div
          class="text-center position-absolute"
          :style="{
            bottom: '2%',
            left: '50%',
            transform: 'translateX(-50%)',
          }"
        >
          <i class="icon icon-scroll-down icon-sm-30 icon-48"></i>
        </div>
      </section>
    </article>
    <article
      id="1"
      class="vh-100 position-relative overflow-hidden"
      :style="{ backgroundColor: '#E9EAF3' }"
    >
      <b-container class="h-100 text-darkest p-4 p-md-0">
        <b-row align-v="center" class="h-100">
          <b-col cols md="6">
            <div
              class="hl mb-5 d-none d-md-block"
              :style="{
                width: '6rem',
                height: '3px',
                background: '#161616',
              }"
            ></div>
            <h1
              class="text-heading text-36 text-lg-76 highlight w-75 highlight-light text-nowrap"
            >
              <!-- <img
                :src="require('@/assets/heading/h1-home-2.svg')"
                alt=""
                class="h-100 d-none d-md-block"
              />
              <img
                :src="require('@/assets/heading/h1-home-2.svg')"
                alt=""
                class="w-100 d-block d-md-none"
              /> -->
              SYSTEM
            </h1>
            <small class="text-14 text-lg-20">
              로스트는 토너먼트를 위한 모든 기능을 제공합니다.
            </small>
            <h2 class="mt-3 mt-md-5 text-24 text-lg-50 fw-300">
              대회신청, 실시간 경기확인, <br />
              랭킹관리 까지
            </h2>
            <section class="mt-4 d-md-flex">
              <b-btn
                variant="text-black"
                class="mr-3 p-0"
                @click="tabIndex = i"
                v-for="(tab, i) in tabs"
                :key="i"
              >
                <span
                  class="fw-700 text-14 text-lg-24"
                  :class="tabIndex == i ? 'text-primary' : ''"
                  :style="
                    tabIndex == i ? { borderBottom: '2px solid #4e58e2' } : {}
                  "
                >
                  {{ tab.name }}</span
                >
              </b-btn>
            </section>
            <div
              class="vl my-5 d-none d-md-block"
              :style="{
                width: '3px',
                height: '6rem',
                background: '#161616',
              }"
            ></div>
          </b-col>
          <b-col cols md="6" class="position-relative ">
            <b-tabs v-model="tabIndex" nav-wrapper-class="d-none">
              <b-tab v-for="(tab, i) in tabs" :key="i">
                <figure
                  class="h-100 bg-img ratio-110"
                  :style="{
                    backgroundImage: `url(${tab.image})`,
                    backgroundSize: 'contain',
                  }"
                ></figure>
              </b-tab>
            </b-tabs>
            <img
              :src="require('@/assets/balls/ball-system-2.png')"
              class="position-absolute pointer-events-none"
              :style="{ bottom: '30%', left: '-4rem', zIndex: '1' }"
              alt=""
            />
          </b-col>
        </b-row>
      </b-container>
      <img
        :src="require('@/assets/balls/ball-system-1.png')"
        class="position-absolute pointer-events-none"
        :style="{ top: '10%', right: '2rem', zIndex: '1', width: '15%' }"
        alt=""
      />
    </article>
    <article
      id="2"
      class="vh-100 position-relative"
      :style="{
        backgroundImage: `url(${require('@/assets/bg-tournament.png')})`,
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }"
    >
      <b-container class="h-100 text-darkest position-relative">
        <b-row class="h-100" align-v="center">
          <b-col md="5" class="px-5 pt-5 px-md-2 pt-md-0">
            <img
              :src="require('@/assets/balls/ball-tournament-2.png')"
              class="position-absolute pointer-events-none  d-none d-md-block"
              :style="{ top: '-10%', right: '-10%', zIndex: '1' }"
              alt=""
            />

            <div
              class="vl d-none d-md-block"
              :style="{
                width: '3px',
                height: '6rem',
                background: '#161616',
              }"
            ></div>
            <h1
              class="mt-5 w-75 highlight highlight-light text-heading text-32 text-lg-64 text-nowrap"
            >
              <!-- <img
                :src="require('@/assets/heading/h1-home-3.svg')"
                alt=""
                class="h-100 d-none d-md-block"
              />
              <img
                :src="require('@/assets/heading/h1-home-3.svg')"
                alt=""
                class="w-100 d-block d-md-none"
              /> -->
              손끝에서 시작되는 <br />
              스쿼시의 미래
            </h1>
            <h2 class="mt-4 pb-4 text-20 text-lg-36 fw-400">
              토너먼트에서 직접 경험하세요
            </h2>
            <img
              :src="require('@/assets/balls/ball-tournament-3.png')"
              class="position-absolute pointer-events-none d-none d-md-block"
              :style="{ bottom: '20%', left: '10%', zIndex: '1' }"
              alt=""
            />
            <img
              :src="require('@/assets/balls/ball-tournament-3.png')"
              class="position-absolute pointer-events-none d-md-none d-block"
              :style="{ bottom: '-40%', left: '0', zIndex: '1', width: '30%' }"
              alt=""
            />
            <div
              class="hl mt-5 mb-md-5"
              :style="{
                width: '6rem',
                height: '3px',
                background: '#161616',
              }"
            ></div>
            <div class="d-none d-md-block" :style="{ height: '15rem' }"></div>
          </b-col>
          <b-col md="7">
            <figure
              class="bg-img w-100 ratio-90 d-none d-md-block"
              :style="{
                backgroundImage: `url(${require('@/assets/tournament.png')})`,
                backgroundSize: 'cover',
              }"
            ></figure>
            <figure
              class="bg-img w-100 ratio-95 ml-n5 d-block d-md-none"
              :style="{
                backgroundImage: `url(${require('@/assets/tournament.png')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }"
            ></figure>
          </b-col>
        </b-row>
        <img
          :src="require('@/assets/balls/ball-tournament-1.png')"
          class="position-absolute pointer-events-none"
          :style="{ top: '10%', right: '10%', zIndex: '1', width: '15%' }"
          alt=""
        />
      </b-container>
    </article>
    <article
      id="3"
      class="vh-100 d-flex flex-column align-items-center justify-content-center"
      :style="{
        backgroundImage: `url(${require('@/assets/bg-vision.png')})`,
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }"
    >
      <b-container class="d-none d-md-block">
        <b-row align-h="center">
          <b-col md="6">
            <figure
              class="card bg-img w-100 ratio-60"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-1.png')})`,
              }"
            >
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-48 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-1.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    Route 17
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    스포츠 맵 그 이상의 가치
                  </small>
                </div>
                <div class="">
                  <b-btn
                    variant="secondary"
                    class="rounded-circle"
                    :style="{ width: '5rem', height: '5rem' }"
                  >
                    <i class="icon icon-36 icon-arr-right"></i>
                  </b-btn>
                </div>
              </figcaption>
            </figure>
          </b-col>
          <b-col md="6">
            <figure
              class="card bg-img w-100 ratio-60"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-2.png')})`,
              }"
            >
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-48 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-2.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    R:Inven
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    사용자 중심의 신개념 용품관리
                  </small>
                </div>
                <div class="">
                  <b-btn
                    variant="secondary"
                    class="rounded-circle"
                    :style="{ width: '5rem', height: '5rem' }"
                  >
                    <i class="icon icon-36 icon-arr-right"></i>
                  </b-btn>
                </div>
              </figcaption>
            </figure>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col md="3">
            <figure
              class="card bg-img w-100 ratio-125 d-none d-md-block"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-3.png')})`,
              }"
            >
              <b-btn
                variant="third"
                class="position-absolute rounded-circle"
                :style="{
                  width: '5rem',
                  height: '5rem',
                  right: '8%',
                  top: '8%',
                }"
              >
                <i class="icon icon-36 icon-arr-right"></i>
              </b-btn>
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-31 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-3.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    ROSTv
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    토너먼트 중계의 새로운 기준
                  </small>
                </div>
              </figcaption>
            </figure>
            <figure
              class="card bg-img w-100 ratio-120 d-block d-md-none"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-3.png')})`,
              }"
            >
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-31 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-4.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    Rally
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    토너먼트 중계의 새로운 기준
                  </small>
                </div>
              </figcaption>
            </figure>
          </b-col>
          <b-col md="3">
            <figure
              class="card bg-img w-100 ratio-125 d-none d-md-block"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-4.png')})`,
              }"
            >
              <b-btn
                variant="third"
                class="position-absolute rounded-circle"
                :style="{
                  width: '5rem',
                  height: '5rem',
                  right: '8%',
                  top: '8%',
                }"
              >
                <i class="icon icon-36 icon-arr-right"></i>
              </b-btn>
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-31 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-4.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    Rally
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    대회에 참여하는 새로운 방법
                  </small>
                </div>
              </figcaption>
            </figure>
            <figure
              class="card bg-img w-100 ratio-120 d-block d-md-none"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-4.png')})`,
              }"
            >
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-48 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-5.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    Rally
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    대회에 참여하는 새로운 방법
                  </small>
                </div>
              </figcaption>
            </figure>
          </b-col>
          <b-col md="6">
            <figure
              class="card bg-img w-100 ratio-60"
              :style="{
                backgroundImage: `url(${require('@/assets/vision/vision-5.png')})`,
              }"
            >
              <figcaption
                class="position-absolute d-flex align-items-center justify-content-between w-100 px-3 px-md-4 pb-3 pb-md-5"
                :style="{ bottom: '0' }"
              >
                <div class="">
                  <h1 class="text-heading mb-0 text-36 text-lg-48 text-white">
                    <!-- <img
                      :src="require('@/assets/heading/h1-home-4-5.svg')"
                      alt=""
                      class="h-100"
                    /> -->
                    R-Market
                  </h1>
                  <small
                    class="fw-500 text-14 text-white text-truncate w-100 d-block"
                  >
                    수수료 없는 네트워크 프리마켓
                  </small>
                </div>
                <b-btn
                  variant="secondary"
                  class="rounded-circle"
                  :style="{ width: '5rem', height: '5rem' }"
                >
                  <i class="icon icon-36 icon-arr-right"></i>
                </b-btn>
              </figcaption>
            </figure>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="d-block d-md-none">
        <carousel
          :perPage="1"
          paginationActiveColor="#4e58e2"
          paginationColor="#222"
          :paginationPadding="6"
          class="px-5"
          :loop="true"
        >
          <carousel-slide v-for="(item, i) in visions" :key="i">
            <h1 class="mb-0 text-heading text-36 text-darkest">
              {{ item.heading }}
            </h1>
            <small class="text-14 text-darkest text-truncate">
              {{ item.text }}
            </small>
            <figure
              class="mt-3 h-100 bg-img ratio-165 position-relative"
              :style="{
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
              }"
            >
              <b-btn
                :variant="item.btnVariant"
                class="rounded-circle position-absolute px-3 py-2"
                :style="{
                  bottom: '20%',
                  right: '4%',
                  zIndex: '10',
                }"
                v-if="item.url !== null"
              >
                <i class="icon icon-36 icon-arr-right mt-1"></i>
              </b-btn>
            </figure>
          </carousel-slide>
        </carousel>
      </b-container>
    </article>
    <article id="4" class="vh-100" :style="{ backgroundColor: '#0E0D0E' }">
      <b-container class="h-100 position-relative">
        <b-row class="h-100" align-v="center" align-h="center">
          <b-col cols="11" md="6" class="position-relative mt-5 mt-md-0">
            <figure
              class="card-goal bg-img w-75 ratio-75 ml-auto mr-4 mb-0"
              :style="{
                backgroundImage: `url(${require('@/assets/goal.png')})`,
              }"
            ></figure>
            <div class="card-border bg-img w-75 ratio-75 ml-auto"></div>
          </b-col>
          <b-col cols="11" md="6" class="mt-n5 mt-md-0">
            <h1
              class="text-heading text-white text-30 text-lg-48 w-75 highlight highlight-fourth text-nowrap"
            >
              <!-- <img
                :src="require('@/assets/heading/h1-home-5.svg')"
                alt=""
                class="h-100 d-none d-md-block"
              />
              <img
                :src="require('@/assets/heading/h1-home-5.svg')"
                alt=""
                class="w-100 d-block d-md-none"
              /> -->
              GOAL OF ROST
            </h1>
            <p
              class="mt-3 mt-md-5 mb-md-5 pr-3 pr-md-0 text-14 text-lg-18 text-white lh-180"
            >
              진보된 디지털, 모바일 환경에 맞춰 기존의 데이터 기록 방식을
              개선하는 것.<br />
              스쿼시의 새로운 가치를 이끌어내 이제까지 발견하지 못했던 재미를
              창출하는 것.<br />
              플랫폼을 활용하는 사용자 모두가 서로에게 실용적이고 유익한
              데이터를 제공하는 것.<br /><br />
              로스트는 스쿼시를 즐기는 새로운 기준을 제시합니다.
            </p>
          </b-col>
        </b-row>
        <img
          :src="require('@/assets/balls/ball-goal-1.png')"
          class="position-absolute pointer-events-none"
          :style="{ top: '5%', right: '-10%', zIndex: '1', width: '20%' }"
          alt=""
        />
        <img
          :src="require('@/assets/balls/ball-goal-2.png')"
          class="position-absolute pointer-events-none"
          :style="{ bottom: '8%', left: '-5%', zIndex: '1', width: '18%' }"
          alt=""
        />
      </b-container>
    </article>
    <side-btns :sections="sections" :active="active" :options="options" />
  </main>
</template>

<script>
// @ is an alias to /src
import SideBtns from "../components/SideBtns";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    SideBtns,
    Carousel: Carousel,
    CarouselSlide: Slide,
  },
  data() {
    return {
      sections: [{ idx: 0 }, { idx: 1 }, { idx: 2 }, { idx: 3 }, { idx: 4 }],
      active: 0,
      options: [
        {
          type: "white",
        },
        {
          type: "dark",
        },
        {
          type: "white",
        },
        {
          type: "dark",
        },
        {
          type: "white",
        },
      ],
      screenHeight: Number,
      tabIndex: 0,
      tabs: [
        { name: "SCHEDULE", image: require("@/assets/system-0.png") },
        { name: "RANKING", image: require("@/assets/system-1.png") },
        { name: "PLAYER", image: require("@/assets/system-2.png") },
        { name: "REFEREE", image: require("@/assets/system-3.png") },
      ],
      visions: [
        {
          heading: "Route 17",
          text: "스포츠 맵 그 이상의 가치",
          url: "",
          btnVariant: "secondary",
          image: require("@/assets/vision/vision-1.png"),
        },
        {
          heading: "R:Inven",
          text: "사용자 중심의 신개념 용품관리",
          url: "",
          btnVariant: "secondary",
          image: require("@/assets/vision/vision-2.png"),
        },
        {
          heading: "ROSTv",
          text: "토너먼트 중계의 새로운 기준",
          url: "",
          btnVariant: "third",
          image: require("@/assets/vision/vision-3.png"),
        },
        {
          heading: "Rally",
          text: "대회에 참여하는 새로운 방법",
          url: "",
          btnVariant: "third",
          image: require("@/assets/vision/vision-4.png"),
        },
        {
          heading: "R-Market",
          text: "수수료 없는 네트워크 프리마켓",
          url: "",
          image: require("@/assets/vision/vision-5.png"),
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.screenHeight = screen.availHeight;
  },
  methods: {
    handleScroll() {
      const y = window.pageYOffset;
      const currentSection = Math.ceil(y / this.screenHeight);

      this.active = currentSection;
    },
  },
};
</script>
