<template>
  <b-navbar
    toggleable="md"
    :variant="navActive ? 'white' : 'transparant'"
    tag="nav"
    class="pt-2 pt-md-4 pb-md-3 fixed-top"
    :style="{
      transition: '0.2s',
      zIndex: '1000',
      paddingLeft: '10%',
      paddingRight: '10%',
    }"
  >
    <b-navbar-brand @click="gotoHome">
      <img
        :src="
          navActive
            ? require('@/assets/logo.svg')
            : require('@/assets/logo-white.svg')
        "
        alt=""
        class="logo"
        :style="{ maxWidth: '144px', cursor: 'pointer' }"
      />
    </b-navbar-brand>

    <b-navbar-toggle
      target="nav-collapse"
      v-if="$route.path === '/' || $route.path === '/apply'"
    ></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto mr-5" v-if="$route.path === '/'">
        <a
          v-for="(link, i) in links"
          :key="i"
          :href="`#` + (i + 1)"
          class="mr-4 my-2 my-md-0"
          :class="navActive ? 'text-gray-dark' : 'text-white'"
        >
          {{ link.name }}</a
        >
      </b-navbar-nav>
      <b-navbar-nav v-if="$route.path === '/'" class="mr-0 mt-n1">
        <b-btn
          variant="secondary"
          class="shadow-sm rounded my-2 my-md-0 text-nowrap"
          @click="$router.push('/apply')"
          :style="{ width: '7rem' }"
        >
          제휴 및 문의
        </b-btn>
      </b-navbar-nav>
      <b-navbar-nav v-if="$route.path === '/apply'" class="ml-auto mt-n1">
        <b-btn
          variant="secondary"
          class="shadow-sm rounded my-2 my-md-0 px-3"
          @click="$router.push('/introduce')"
          :style="{ width: '7rem' }"
        >
          ABOUT US
        </b-btn>
      </b-navbar-nav>
      <b-navbar-nav v-if="$route.path === '/introduce'" class="ml-auto mt-n1">
        <b-btn
          variant="secondary"
          class="shadow-sm rounded my-2 my-md-0 px-3"
          @click="$router.push('/')"
          :style="{ width: '7rem' }"
        >
          HOME
        </b-btn>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      navActive: false,
      meta: this.$route.meta,
      links: [
        {
          name: "시스템",
        },
        {
          name: "스토리",
        },
        {
          name: "서비스",
        },
        {
          name: "비전",
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const y = window.pageYOffset;

      if (this.$route.path === "/" && y > 200) {
        this.navActive = true;
      } else if (this.$route.path === "/" && y < 200) {
        this.navActive = false;
      }

      if (this.$route.path === "/apply" && y > 200) {
        this.navActive = true;
      } else if (this.$route.path === "/apply" && y < 200) {
        this.navActive = false;
      }

      if (this.$route.path === "/policy" || this.$route.path === "/introduce") {
        this.navActive = true;
      }
    },
    gotoHome() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
  },
  // mounted() {
  //   if (this.$route.path === "/policy" || this.$route.path === "/introduce") {
  //     this.navActive = true;
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.logo {
  @media all and (max-width: 789px) {
    width: 5rem;
  }
}
</style>
