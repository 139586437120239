<template>
  <div id="app" class="position-relative">
    <global-nav></global-nav>
    <router-view />
    <global-footer></global-footer>
  </div>
</template>
<script>
import GlobalNav from "@/components/GlobalNav";
import GlobalFooter from "@/components/GlobalFooter";
export default {
  components: {
    GlobalNav,
    GlobalFooter,
  },
};
</script>

<style lang="scss"></style>
